import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const AuthAPI = {
  login: async function ({ email, password }, cancel = false) {
    const response = await api.request({
      url: `/login`,
      method: "POST",
      params: {
        email: email,
        password: password,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  logout: async function ({ token }, cancel = false) {
    const response = await api.request({
      url: "/logout",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.getPaginated.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  trocar: async function (
    { token, antiga, nova, novaConfirma },
    cancel = false
  ) {
    const response = await api.request({
      url: "/change_password",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        old_password: antiga,
        new_password: nova,
        new_password_confirmation: novaConfirma,
      },
      signal: cancel
        ? cancelApiObject[this.getPaginated.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  recuperar: async function ({ email }, cancel = false) {
    const response = await api.request({
      url: `/forget`,
      method: "POST",
      params: {
        email: email,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  reset: async function (
    { token, email, password, newPassword },
    cancel = false
  ) {
    const response = await api.request({
      url: "/reset",
      method: "POST",
      params: {
        token: token,
        email: email,
        password: password,
        password_confirmation: newPassword,
      },
      signal: cancel
        ? cancelApiObject[this.getPaginated.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AuthAPI);
