import { Outlet } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { Container } from "./styled";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import Preloader from "../../components/Preloader/Preloader";
import DialogBox from "../../components/Dialog/DialogBox";
import { Footer } from "../../components/Footer/Footer";
import { isMobile } from "react-device-detect";

const Root = () => {
  return (
    <Container>
      <Preloader />
      <AlertDismissible />
      <DialogBox />
      <Header />
      <Outlet />
      <Footer />
    </Container>
  );
};

export default Root;
