import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Container } from "./styled";
import { colors } from "@material-tailwind/react/types/generic";
import { useDialog } from "../../contexts/dialog";

interface DialogBoxProps {}

const DialogBox = ({}: DialogBoxProps) => {
  const { actionDialog, openDialog, setOpenDialog, messageDialog } =
    useDialog();
  return (
    <Dialog size="xs" open={openDialog} handler={() => setOpenDialog(false)}>
      <DialogHeader>Confirmação</DialogHeader>
      <DialogBody>{messageDialog}</DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={() => setOpenDialog(false)}
          className="mr-1"
        >
          <span>Cancelar</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          onClick={() => {
            actionDialog();
            setOpenDialog(false);
          }}
        >
          <span>Confirmar</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DialogBox;
