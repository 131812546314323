import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { userProps } from "../contexts/auth";

export const protectedLoader = (user:userProps | null,roles:string[] | null) => ({ request }: LoaderFunctionArgs) => {
    // If the user is not logged in and tries to access `/protected`, we redirect
    // them to `/login` with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication
    if(user && user.token !== null){
      let hasValidRole = false;
      user.roles.map((roleUser:string,index:number)=>{
        roles?.map((roleNeeded:string,index1:number)=>{
          // console.log(roleUser,roleNeeded);
          if(roleUser === roleNeeded){
            hasValidRole= true;
          }
        });
      });
      if(hasValidRole){
        return null;
      }else{
        let params = new URLSearchParams();
        params.set("from", new URL(request.url).pathname);
        return redirect("/login?" + params.toString());
      }
    }else{
      let params = new URLSearchParams();
      params.set("from", new URL(request.url).pathname);
      return redirect("/login?" + params.toString());
    }
  };
