import {
  Link,
  useFetcher,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { Container, Li, Nav, Ul } from "./styled";
import {
  Drawer,
  DrawerProps,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import BurgerMenu from "../ui/BurgerMenu";
import { useMenu } from "../../contexts/menu";
import { useAuth, userProps } from "../../contexts/auth";

const HeaderNav = () => {
  let { user } = useAuth();
  let fetcher = useFetcher();
  const navigate = useNavigate();
  const { openMenu, setOpenMenu } = useMenu();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  let isLoggingOut = fetcher.formData != null;

  const closeDrawer = () => {
    console.log("open", openMenu);
    if (openMenu === true) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    let admin = false;
    user?.roles.map((roleUser: string, index: number) => {
      if (roleUser === "admin") {
        admin = true;
      }
    });
    setIsAdmin(admin);
  }, [user]);

  return user ? (
    <Container>
      <BurgerMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <Drawer
        open={openMenu}
        // onClose={closeDrawer}
        placement="right"
        transition={{ type: "tween", duration: 1 }}
        className=""
      >
        <List className="pt-20">
          <ListItem
            className="text-secondary"
            onClick={() => {
              navigate("/");
              setTimeout(() => {
                setOpenMenu(false);
              }, 100);
            }}
          >
            <ListItemPrefix>
              <svg
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 54.1 51.6"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  xmlns="https://www.w3.org/2000/svg"
                  d="M53,21.6L29,0.8c-1.2-1-2.9-1-4.1,0L1.1,21.7c-2.2,1.9-0.8,5.5,2.1,5.5c1.7,0,3.1,1.4,3.1,3.1v18.2c0,1.7,1.4,3.1,3.1,3.1  h10.9v-16c0-1,0.8-1.8,1.8-1.8H32c1,0,1.8,0.8,1.8,1.8v16h10.4c1.7,0,3.1-1.4,3.1-3.1V30.2c0-1.7,1.4-3.1,3.1-3.1h0.6  C53.8,27.1,55.2,23.5,53,21.6"
                />
              </svg>
            </ListItemPrefix>
            Home
          </ListItem>
          {isAdmin && (
            <ListItem
              className="text-secondary"
              onClick={() => {
                navigate("/categorias");
                setTimeout(() => {
                  setOpenMenu(false);
                }, 100);
              }}
            >
              <ListItemPrefix>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <g xmlns="https://www.w3.org/2000/svg">
                    <path
                      className="st0"
                      d="M384,460.8c-35.35,0-64-28.65-64-64c0-35.32,28.65-64,64-64s64,28.68,64,64C448,432.15,419.35,460.8,384,460.8   z M64,345.6h102.4V448H64V345.6z M243.2,98.32l49.4,80.88h-98.8L243.2,98.32z"
                    />
                    <path
                      className="st1"
                      d="M243.2,0L102.4,230.4H384L243.2,0z M243.2,98.32l49.4,80.88h-99.08L243.2,98.32z M384,281.6   c-63.75,0-115.2,51.48-115.2,115.2c0,63.75,51.45,115.2,115.2,115.2s115.2-51.45,115.2-115.2C499.2,333.08,447.75,281.6,384,281.6z    M384,460.8c-35.33,0-64-28.65-64-64c0-35.32,28.67-64,64-64s64,28.68,64,64C448,432.15,419.33,460.8,384,460.8z M217.6,294.4H12.8   v204.8h204.8V294.4z M166.4,448H64V345.6h102.4V448z"
                    />
                  </g>
                </svg>
              </ListItemPrefix>
              Categorias
            </ListItem>
          )}
          {isAdmin && (
            <ListItem
              className="text-secondary"
              onClick={() => {
                navigate("/grupos");
                setTimeout(() => {
                  setOpenMenu(false);
                }, 100);
              }}
            >
              <ListItemPrefix>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 303.374 303.374"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <g xmlns="https://www.w3.org/2000/svg">
                    <path d="M268.291,177.313c-4.144,0-8.12,0.727-11.814,2.052l-17.319-27.524c10.331-10.171,16.751-24.302,16.751-39.91   c0-30.899-25.138-56.037-56.037-56.037s-56.037,25.138-56.037,56.037c0,12.226,3.947,23.54,10.617,32.762l-33.742,33.954   c-4.438-2.404-9.515-3.771-14.907-3.771c-5.323,0-10.339,1.336-14.736,3.684l-19.721-20.688c5.93-7.037,9.514-16.113,9.514-26.014   c0-22.293-18.137-40.43-40.43-40.43S0,109.565,0,131.858s18.136,40.43,40.429,40.43c5.854,0,11.416-1.261,16.444-3.509   l21.387,22.436c-2.456,4.474-3.856,9.606-3.856,15.06c0,17.313,14.085,31.398,31.398,31.398s31.398-14.085,31.398-31.398   c0-5.388-1.365-10.462-3.766-14.897l33.756-33.969c9.207,6.635,20.491,10.559,32.68,10.559c8.815,0,17.157-2.052,24.584-5.694   l17.197,27.329c-5.258,6.136-8.446,14.097-8.446,22.793c0,19.345,15.739,35.084,35.084,35.084s35.083-15.739,35.083-35.084   S287.636,177.313,268.291,177.313z M161.834,111.931c0-20.974,17.063-38.037,38.037-38.037s38.037,17.063,38.037,38.037   s-17.063,38.037-38.037,38.037S161.834,132.904,161.834,111.931z M105.802,219.673c-7.388,0-13.398-6.011-13.398-13.398   s6.011-13.398,13.398-13.398s13.398,6.011,13.398,13.398S113.19,219.673,105.802,219.673z M18,131.858   c0-12.368,10.062-22.43,22.429-22.43s22.43,10.062,22.43,22.43s-10.062,22.43-22.43,22.43S18,144.226,18,131.858z M268.291,229.48   c-9.42,0-17.084-7.664-17.084-17.084s7.664-17.084,17.084-17.084s17.083,7.664,17.083,17.084S277.71,229.48,268.291,229.48z" />
                  </g>
                </svg>
              </ListItemPrefix>
              Grupos
            </ListItem>
          )}
          <ListItem
            className="text-secondary"
            onClick={() => {
              navigate("/dispositivos");
              setTimeout(() => {
                setOpenMenu(false);
              }, 100);
            }}
          >
            <ListItemPrefix>
              <svg
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 35 35"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path d="M27.059,31.25H7.941A4.2,4.2,0,0,1,3.75,27.059V7.941A4.2,4.2,0,0,1,7.941,3.75H27.059A4.2,4.2,0,0,1,31.25,7.941V27.059A4.2,4.2,0,0,1,27.059,31.25ZM7.941,6.25A1.693,1.693,0,0,0,6.25,7.941V27.059A1.693,1.693,0,0,0,7.941,28.75H27.059a1.693,1.693,0,0,0,1.691-1.691V7.941A1.693,1.693,0,0,0,27.059,6.25Z" />
                <path d="M22.119,24.75H12.881a2.635,2.635,0,0,1-2.631-2.631V12.881a2.634,2.634,0,0,1,2.631-2.631h9.238a2.634,2.634,0,0,1,2.631,2.631v9.238A2.635,2.635,0,0,1,22.119,24.75Zm-9.238-12a.131.131,0,0,0-.131.131v9.238a.131.131,0,0,0,.131.131h9.238a.131.131,0,0,0,.131-.131V12.881a.131.131,0,0,0-.131-.131Z" />
                <path d="M12.982,4.4A1.25,1.25,0,0,1,11.734,3.2l-.034-1A1.25,1.25,0,0,1,12.908.905,1.263,1.263,0,0,1,14.2,2.112l.034,1A1.252,1.252,0,0,1,13.025,4.4Z" />
                <path d="M17.516,4.4A1.251,1.251,0,0,1,16.267,3.2l-.033-1A1.249,1.249,0,0,1,17.442.905a1.223,1.223,0,0,1,1.291,1.208l.033,1A1.25,1.25,0,0,1,17.558,4.4Z" />
                <path d="M22.049,4.4A1.251,1.251,0,0,1,20.8,3.2l-.033-1A1.249,1.249,0,0,1,21.975.905a1.22,1.22,0,0,1,1.291,1.208l.033,1A1.25,1.25,0,0,1,22.091,4.4Z" />
                <path d="M32.081,23.334a1.25,1.25,0,0,1-.041-2.5l1-.034a1.25,1.25,0,1,1,.083,2.5l-1,.034Z" />
                <path d="M32.081,18.8a1.25,1.25,0,0,1-.042-2.5l1-.034a1.25,1.25,0,1,1,.085,2.5l-1,.034Z" />
                <path d="M32.081,14.266a1.25,1.25,0,0,1-.041-2.5l1-.033a1.25,1.25,0,1,1,.083,2.5l-1,.033Z" />
                <path d="M22.066,34.1a1.25,1.25,0,0,1-1.249-1.208l-.033-1A1.251,1.251,0,0,1,21.991,30.6,1.238,1.238,0,0,1,23.283,31.8l.033,1a1.249,1.249,0,0,1-1.207,1.291Z" />
                <path d="M17.532,34.1a1.249,1.249,0,0,1-1.248-1.209l-.033-1a1.25,1.25,0,0,1,1.208-1.29A1.237,1.237,0,0,1,18.75,31.8l.033,1a1.249,1.249,0,0,1-1.208,1.29Z" />
                <path d="M13,34.1a1.249,1.249,0,0,1-1.248-1.209l-.034-1a1.25,1.25,0,0,1,2.5-.082l.034,1a1.25,1.25,0,0,1-1.208,1.29Z" />
                <path d="M1.918,14.216a1.25,1.25,0,0,1-.041-2.5l1-.033a1.25,1.25,0,1,1,.083,2.5l-1,.033Z" />
                <path d="M1.918,18.75a1.25,1.25,0,0,1-.041-2.5l1-.034a1.25,1.25,0,1,1,.083,2.5l-1,.034Z" />
                <path d="M1.918,23.284a1.25,1.25,0,0,1-.042-2.5l1-.034a1.269,1.269,0,0,1,1.292,1.206,1.251,1.251,0,0,1-1.207,1.292l-1,.034Z" />
              </svg>
            </ListItemPrefix>
            Dispositivos
          </ListItem>
          {/* <ListItem
            className="text-secondary"
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            <ListItemPrefix>
              <svg
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z"
                  clipRule="evenodd"
                />
              </svg>
            </ListItemPrefix>
            Notificações
            <ListItemSuffix>
              <Chip
                value="5"
                size="sm"
                color="green"
                className="rounded-full bg-secondary"
              />
            </ListItemSuffix>
          </ListItem> */}
          {isAdmin && (
            <ListItem
              className="text-secondary"
              onClick={() => {
                navigate("/usuarios");
                setTimeout(() => {
                  setOpenMenu(false);
                }, 100);
              }}
            >
              <ListItemPrefix>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    xmlns="https://www.w3.org/2000/svg"
                    d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                  />
                </svg>
              </ListItemPrefix>
              Usuários
            </ListItem>
          )}
          <ListItem
            className="text-secondary"
            onClick={() => {
              navigate("/perfil");
              setTimeout(() => {
                setOpenMenu(false);
              }, 100);
            }}
          >
            <ListItemPrefix>
              <svg
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                  clipRule="evenodd"
                />
              </svg>
            </ListItemPrefix>
            Perfil
          </ListItem>
          <ListItem
            className="text-secondary"
            onClick={() => {
              navigate("/trocar");
              setTimeout(() => {
                setOpenMenu(false);
              }, 100);
            }}
          >
            <ListItemPrefix>
              <svg
                xmlns="https://www.w3.org/2000/svg"
                height="800px"
                width="800px"
                version="1.1"
                id="Capa_1"
                viewBox="0 0 367.578 367.578"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  xmlns="https://www.w3.org/2000/svg"
                  d="M281.541,97.751c0-53.9-43.851-97.751-97.751-97.751S86.038,43.851,86.038,97.751c0,44.799,30.294,82.652,71.472,94.159  v144.668c0,4.026,1.977,9.1,4.701,12.065l14.514,15.798c1.832,1.993,4.406,3.136,7.065,3.136s5.233-1.143,7.065-3.136l14.514-15.798  c2.724-2.965,4.701-8.039,4.701-12.065v-7.387l14.592-9.363c2.564-1.646,4.035-4.164,4.035-6.909c0-2.744-1.471-5.262-4.036-6.907  l-14.591-9.363v-0.207l14.592-9.363c2.564-1.646,4.035-4.164,4.035-6.909c0-2.744-1.471-5.262-4.036-6.907l-14.591-9.363v-0.207  l14.592-9.363c2.564-1.646,4.035-4.164,4.035-6.908c0-2.745-1.471-5.263-4.036-6.909l-14.591-9.363V191.91  C251.246,180.403,281.541,142.551,281.541,97.751z M183.789,104.948c-20.985,0-37.996-17.012-37.996-37.996  s17.012-37.996,37.996-37.996s37.996,17.012,37.996,37.996S204.774,104.948,183.789,104.948z"
                />
              </svg>
            </ListItemPrefix>
            Trocar Senha
          </ListItem>
        </List>
        <fetcher.Form method="post" action="/logout">
          <Button
            type="submit"
            disabled={isLoggingOut}
            className="mt-3 ml-5 bg-secondary text-primary"
            size="sm"
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            {isLoggingOut ? "Loging out..." : "Log out"}
          </Button>
        </fetcher.Form>
      </Drawer>
    </Container>
  ) : (
    <Container>
      {/* <Nav>
        <Ul>
          <Li>
            <Link to="/">Home</Link>
          </Li>
          <Li>
            <Link to="/login">Login</Link>
          </Li>
        </Ul>
      </Nav> */}
    </Container>
  );
};

export default HeaderNav;
