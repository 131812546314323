import {LoaderFunctionArgs,
redirect,
} from "react-router-dom";

export const recuperarAction = (recuperar: (email: string )=>any) => async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let email = formData.get("email") as string | null;
   
    if (!email) {
      return {
        error: "Você precisa fornecer um e-mail válido para recuperar sua senha",
      };
    }

    let recuperarResponse;
    try {
    recuperarResponse =  await recuperar(email);
    } catch (error) {
      return {
        error: "Problema ao recuperar senha. Tente novamente",
      };
    }
    // let redirectTo = formData.get("redirectTo") as string | null;
    // return redirect(redirectTo || "/");
    return recuperarResponse;
  };
  
  export const recuperarLoader = (isAuthenticated:boolean | null) => async () => {
    if (isAuthenticated) {
      return redirect("/");
    }
    return null;
  };