import {LoaderFunctionArgs,
redirect,
} from "react-router-dom";
import { UsersAPI } from "../apis/UsersAPI";
import { AxiosError, isAxiosError } from "axios";
import { useAuth } from "../contexts/auth";

export const adicionarUsuarioAction = (token: string | undefined) => async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let nivel = formData.get("nivel") as string | null;
    let permissionsAddedString = formData.get("permissionsAdded") as string | null;
    let nome = formData.get("nome") as string | null;
    let email = formData.get("email") as string | null;
    let celular = formData.get("celular") as string | null;
    let senha = formData.get("senha") as string | null;
    let senhaConfirma = formData.get("senha_confirma") as string | null;

    let permissionsAdded=[];

    if(!token){
      return{
        error:"Você não tem permissão para a ação"
      } 
    }
   
    if (!nivel) {
      return {
        error: "Você precisa selecionar um nível de usuário",
      };
    }

    if (!permissionsAddedString) {
      permissionsAddedString="";
    }
    permissionsAdded = permissionsAddedString.split(",");

    if (!nome) {
      return {
        error: "Você precisa fornecer um nome para cadastrar um usuário",
      };
    }

    if (!email) {
      return {
        error: "Você precisa fornecer um email para cadastrar um usuário",
      };
    }

    if (!celular) {
      return {
        error: "Você precisa fornecer um celular para cadastrar um usuário",
      };
    }

    if (!senha) {
      return {
        error: "Você precisa fornecer uma senha para cadastrar um usuário",
      };
    }

    if (!senhaConfirma) {
      return {
        error: "Você precisa confirmar a senha cadastrar um usuário",
      };
    }

    try {
       let response = await cadastrarUsuario(token, nivel,permissionsAdded, nome, email, celular,senha,senhaConfirma);
       console.log(response);
       return response;
    } catch (error) {
      console.log(error);
      return {
        error: "Problema ao cadastrar usuário. Tente novamente",
      };
    }
};

const cadastrarUsuario = async (token:string, nivel:string, permissionsAdded:string[], nome:string, email:string, celular:string,senha:string,senhaConfirma:string) => {
  let dados = {name:nome,email:email,phone:celular,password:senha,password_confirmation:senhaConfirma,roles:[nivel],permissions:permissionsAdded};
  console.log(dados);
  const response = await UsersAPI.createUser({
    token:token,
    dados:dados
  }).then((res: any)=>{
    return res;
  }).catch((err: Error | AxiosError)=>{
    if (isAxiosError(err)) {
      return err.response?.data.message;
    }
  })
  return response
}
