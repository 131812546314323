import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigation,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";

const Recuperar = () => {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  let navigation = useNavigation();
  let isRecuperando = navigation.formData?.get("email") != null;

  let actionData = useActionData() as { error: string } | undefined;

  return (
    <Container>
      <Main>
        <Card
          color="transparent"
          shadow={false}
          className=" place-items-center"
        >
          <Typography variant="h4" className="text-primary">
            Recuperar Senha
          </Typography>
          <Typography className="mt-1 font-normal text-primary text-center">
            Preencha os dados abaixo para recuperar sua senha
          </Typography>
          <Form
            method="post"
            replace
            className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"
          >
            <input type="hidden" name="redirectTo" value={from} />
            <div className="mb-1 flex flex-col gap-6">
              <Typography variant="h6" className="-mb-3 text-primary">
                E-mail
              </Typography>
              <Input
                name="email"
                size="lg"
                placeholder="usuario@email.com"
                className=" !border-primary focus:!border-secondary text-primary"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
            </div>
            <Button
              type="submit"
              disabled={isRecuperando}
              className="mt-6 bg-secondary text-primary"
              fullWidth
            >
              {isRecuperando ? "Solicitando" : "Recuperar Senha"}
            </Button>
            {actionData && actionData.error ? (
              <p className="text-danger text-center my-5">{actionData.error}</p>
            ) : null}
          </Form>
          <Link to="/" className="text-primary">
            voltar para Home
          </Link>
        </Card>
      </Main>
    </Container>
  );
};

export default Recuperar;
