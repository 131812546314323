import {LoaderFunctionArgs,
redirect,
} from "react-router-dom";

export const resetAction = (reset: ( token: string, email: string, nova: string, novaConfirma: string)=>any) => async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let token = formData.get("token") as string ;
    let email = formData.get("email") as string ;
    let nova = formData.get("nova") as string;
    let novaConfirma = formData.get("novaConfirma") as string;
   
    if (!token) {
      return {
        error: "Token inválido",
      };
    }
    if (!email) {
      return {
        error: "E-mail inválido",
      };
    }
    if (!nova) {
      return {
        error: "Você precisa fornecer uma senha",
      };
    }
    if (!novaConfirma) {
      return {
        error: "Você precisa confirmar sua senha",
      };
    }

    let resetResponse;

    try {
    resetResponse =  await reset(token,email,nova,novaConfirma);
    } catch (error) {
      return {
        error: "Problema ao reset senha. Tente novamente",
      };
    }
    // let redirectTo = formData.get("redirectTo") as string | null;
    // return redirect(redirectTo || "/");
    return resetResponse;
  };
  
  export const resetLoader = (isAuthenticated:boolean | null) => async () => {
    if (isAuthenticated) {
      return redirect("/");
    }
    return null;
  };