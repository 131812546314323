interface ITime {
  timeInit: string | undefined;
  setTimeInit: React.Dispatch<React.SetStateAction<string>>;
  timeEnd: string | undefined;
  setTimeEnd: React.Dispatch<React.SetStateAction<string>>;
}

export const TimePic = ({
  timeInit,
  setTimeInit,
  timeEnd,
  setTimeEnd,
}: ITime) => {
  return (
    <form className="max-w-[16rem]  grid grid-cols-2 gap-0 my-2">
      <div className="mx-2">
        <label
          htmlFor="start-time"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Hora inicial:
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none"></div>
          <input
            type="time"
            id="start-time"
            className=" border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            min="00:00"
            max="23:59"
            value={timeInit}
            required
            onChange={(e) => setTimeInit(e.target.value)}
          />
        </div>
      </div>
      <div className="mx-2">
        <label
          htmlFor="end-time"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Hora final:
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none"></div>
          <input
            type="time"
            id="end-time"
            className=" border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            min="00:00"
            max="23:59"
            value={timeEnd}
            required
            onChange={(e) => setTimeEnd(e.target.value)}
          />
        </div>
      </div>
    </form>
  );
};
