import tw from "tailwind-styled-components";

export const Container = tw.div`
    flex
    items-center
    justify-center
    flex-col
    w-full
    h-full
    bg-black
    bg-center
    bg-cover
    bg-[url("./assets/images/DSC00198.jpg?CACHE=12")]
    text-white
    mt-[-100px]
`;

export const Main = tw.main`
container
flex
flex-col
flex-nowrap
items-center
justify-center
h-[100vh]
`;
