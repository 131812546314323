import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const GroupsAPI = {
  createGroups: async function ({ token, dados }, cancel = false) {
    const response = await api.request({
      url: `/groups`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: dados,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  updateGroups: async function ({ token, id, dados }, cancel = false) {
    const response = await api.request({
      url: `/groups/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: dados,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  listGroups: async function ({ token, page }, cancel = false) {
    const response = await api.request({
      url: page ? `/groups?page=${page}` : "/groups",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    // console.log(response.data);
    return response.data;
  },
  getGroups: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/groups/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  deleteGroups: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/groups/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(GroupsAPI);
