import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigation,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useEffect, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";

const Login = () => {
  let location = useLocation();
  const { authMessage, setAuthMessage } = useAuth();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";
  let navigation = useNavigation();
  let isLoggingIn = navigation.formData?.get("email") != null;
  let actionData = useActionData() as { error: string } | undefined;
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
  }, []);

  useEffect(() => {
    if (isLoggingIn) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isLoggingIn]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    }
  }, [actionData]);

  return (
    <Container>
      <Main>
        {/* {message !== null && message !== "" && (
          <p className="text-tertiary text-center my-5">{message}</p>
        )} */}
        {/* <Form method="post" replace>
          <input type="hidden" name="redirectTo" value={from} />
          <label>
            Username: <input name="username" />
          </label>{" "}
          <button type="submit" disabled={isLoggingIn}>
            {isLoggingIn ? "Logging in..." : "Login"}
          </button>
          {actionData && actionData.error ? (
            <p style={{ color: "red" }}>{actionData.error}</p>
          ) : null}
        </Form> */}
        <Card
          color="transparent"
          shadow={false}
          className=" place-items-center"
        >
          <Typography variant="h4" className="text-primary">
            Olá, seja bem-vindo!
          </Typography>
          <Typography className="mt-1 font-normal text-primary">
            Faça o login para acessar a plataforma
          </Typography>
          <Form
            method="post"
            replace
            className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"
          >
            <input type="hidden" name="redirectTo" value={from} />
            <div className="mb-1 flex flex-col gap-6">
              <Typography variant="h6" className="-mb-3 text-primary">
                E-mail
              </Typography>
              <Input
                name="email"
                size="lg"
                placeholder="usuario@email.com"
                className=" !border-primary focus:!border-secondary text-primary"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 text-primary">
                Password
              </Typography>
              <Input
                name="senha"
                type="password"
                size="lg"
                placeholder="********"
                className=" !border-primary focus:!border-secondary active:!border-secondary text-primary"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
            </div>
            <Checkbox
              name="keepAuthenticated"
              label={
                <Typography
                  variant="small"
                  className="flex items-center font-normal text-primary"
                >
                  Permanecer logado
                </Typography>
              }
              containerProps={{ className: "-ml-2.5" }}
            />
            <Button
              type="submit"
              disabled={isLoggingIn}
              className="mt-6 bg-secondary text-primary"
              fullWidth
            >
              {isLoggingIn ? "Entrando" : "Entrar"}
            </Button>
            {/* {actionData && actionData.error ? (
              <p style={{ color: "red" }}>{actionData.error}</p>
            ) : null} */}
            <Typography className="mt-4 text-center font-normal text-primary">
              Esqueceu sua senha?{" "}
              <Link to="/recuperar" className="font-medium text-secondary">
                Recuperar Senha
              </Link>
            </Typography>
          </Form>
        </Card>
      </Main>
    </Container>
  );
};

export default Login;
