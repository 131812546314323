import { useAuth } from "../../../contexts/auth";
import { Container, Main } from "./styled";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";

const ProfileProtected = () => {
  const { user } = useAuth();
  return (
    <Container>
      <Main>
        <div className="container flex justify-center">
          <Card className="w-96 shadow-2xl items-center justify-center">
            <CardHeader
              floated={false}
              className="w-80 h-80 text-[80px] bg-secondary text-primary text-center flex items-center justify-center rounded-full"
            >
              {user?.name.split(" ").map((nome) => nome.substring(0, 1))}
            </CardHeader>
            <CardBody className="text-center">
              <Typography variant="h4" color="blue-gray" className="mb-2">
                {user?.name}
              </Typography>
              <Typography variant="h5" className="mb-0 text-secondary">
                Nível
              </Typography>
              <ul>
                {user?.roles.map((role, index) => (
                  <li key={index} className="capitalize">
                    <Typography
                      color="blue-gray"
                      className="font-medium"
                      textGradient
                    >
                      {role}
                    </Typography>
                  </li>
                ))}
              </ul>
              <Typography variant="h5" className="mt-2 text-secondary">
                Permissões
              </Typography>
              <ul>
                {user?.roles.map((role, index) =>
                  role === "admin" ? (
                    <li key={index} className="capitalize">
                      <Typography
                        color="blue-gray"
                        className="font-medium"
                        textGradient
                      >
                        Total
                      </Typography>
                    </li>
                  ) : (
                    user?.permissions &&
                    user?.permissions.map((permission, index) => (
                      <li key={index} className="capitalize">
                        <Typography
                          color="blue-gray"
                          className="font-medium"
                          textGradient
                        >
                          {permission}
                        </Typography>
                      </li>
                    ))
                  )
                )}
              </ul>
            </CardBody>
          </Card>
        </div>
      </Main>
    </Container>
  );
};

export default ProfileProtected;
