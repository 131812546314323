import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useEffect, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";
import { UsersAPI } from "../../../apis/UsersAPI";
import { RolesAPI } from "../../../apis/RolesAPI";
import { AxiosError, isAxiosError } from "axios";
import PhoneInput from "../../../components/ui/PhoneInput";
import { DeviceAPI } from "../../../apis/DeviceAPI";
import { CategoryAPI } from "../../../apis/CategoryAPI";
import { GroupsAPI } from "../../../apis/GroupsAPI";

const EditDeviceProtected = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { authMessage, setAuthMessage, user } = useAuth();
  const { setStartPreloader } = usePreloader();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isEditingDevice = navigation.formData?.get("name") != null;
  let actionData = useActionData() as
    | { error: string; message: string; success: boolean }
    | undefined;
  const [message, setMessage] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);
  const [grupos, setGrupos] = useState<string[]>([]);
  const [grupoEscolhido, setGrupoEscolhido] = useState<number | undefined>(
    undefined
  );
  const [props, setProps] = useState<string>("");

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
  }, []);

  useEffect(() => {
    if (isEditingDevice) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isEditingDevice]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    } else if (actionData && actionData.message && actionData.success) {
      if (actionData.success) {
        setAuthMessage(actionData.message);
        navigate("/dispositivos");
      }
    } else if (typeof actionData === "string") {
      setAuthMessage(actionData);
    }
  }, [actionData]);

  useEffect(() => {
    setStartPreloader(true);
    DeviceAPI.getDevice({ token: user?.token, id: deviceId })
      .then((res: any) => {
        console.log(res);
        setData(res);
        setProps(JSON.stringify(res.props));
        setGrupoEscolhido(res.group_id);
        GroupsAPI.listGroups({
          token: user?.token,
          page: null,
        })
          .then((res2: any) => {
            console.log(res2);
            setGrupos(res2.data);
            setStartPreloader(false);
          })
          .catch((err2: Error | AxiosError) => {
            if (isAxiosError(err2)) {
              console.log(err2.response?.data.message);
              setStartPreloader(false);
            }
          });
      })
      .catch((err: Error | AxiosError) => {
        setStartPreloader(false);
        console.log(err);
      });
  }, []);

  return (
    <Container>
      <Main>
        {data && (
          <Card color="transparent" shadow={false}>
            <Form
              method="post"
              autoComplete="new-password"
              replace
              className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96 flex flex-col flex-nowrap items-center"
            >
              <div className="mb-1 flex flex-col w-full gap-6">
                <Typography variant="h6" className="-mb-3 ">
                  Nome
                </Typography>
                <Input
                  name="nome"
                  type="text"
                  autoComplete="new-password"
                  size="lg"
                  placeholder="Nome do dispositivo"
                  className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  defaultValue={data.name}
                />
                <input name="id" value={deviceId} hidden readOnly />
                <Typography variant="h6" className="-mb-3 ">
                  Mac
                </Typography>
                <Input
                  name="mac"
                  type="text"
                  autoComplete="new-password"
                  size="lg"
                  placeholder="Mac Address do dispositivo"
                  className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  defaultValue={data.mac}
                />
                <Typography variant="h6" className="-mb-3 ">
                  Grupo
                </Typography>
                <Select
                  key={Math.floor(Math.random() * 10000000)}
                  label="Grupo"
                  size="lg"
                  onChange={(value) => {
                    setGrupoEscolhido(Number(value));
                  }}
                  value={grupoEscolhido?.toString()}
                >
                  {grupos &&
                    grupos?.map((gp: any, index: number) => (
                      <Option key={index} value={gp.id.toString()}>
                        {gp.name.charAt(0).toUpperCase() + gp.name.slice(1)}
                      </Option>
                    ))}
                </Select>
                <input name="grupo" value={grupoEscolhido} hidden readOnly />
                <Typography variant="h6" className="-mb-3 ">
                  Propriedades
                </Typography>
                <Textarea
                  defaultValue={JSON.stringify(data.props)}
                  label="Propriedades"
                  onChange={(e) => {
                    setProps(e.target.value);
                  }}
                ></Textarea>
                <input name="props" value={props} hidden readOnly />
              </div>
              <Button
                type="submit"
                disabled={isEditingDevice}
                className="mt-6 bg-secondary "
                fullWidth
              >
                {isEditingDevice ? "Atualizando" : "Atualizar"}
              </Button>
              <Button
                className="mt-6 bg-transparent text-secondary"
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </Form>
          </Card>
        )}
      </Main>
    </Container>
  );
};

export default EditDeviceProtected;
