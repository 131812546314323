import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider } from "@material-tailwind/react";
import { themeAlegriasDeQuintal } from "./theme/theme";
import { AuthProvider } from "./contexts/auth";
import { AlertProvider } from "./contexts/alert";
import { MenuProvider } from "./contexts/menu";
import { PreloaderProvider } from "./contexts/preloader";
import { DialogProvider } from "./contexts/dialog";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ThemeProvider value={themeAlegriasDeQuintal}>
    <PreloaderProvider>
      <AlertProvider>
        <DialogProvider>
          <AuthProvider>
            <MenuProvider>
              <App />
            </MenuProvider>
          </AuthProvider>
        </DialogProvider>
      </AlertProvider>
    </PreloaderProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
