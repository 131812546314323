import { useEffect, useRef, useState } from "react";
import { DeviceAPI } from "../../../apis/DeviceAPI";
import { useAuth } from "../../../contexts/auth";
import { AxiosError, isAxiosError } from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  PencilIcon,
  UserPlusIcon,
  XCircleIcon,
  ChartBarIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { Container, ContentRow, Main } from "./styled";
import { disable } from "workbox-navigation-preload";
import { useNavigate } from "react-router-dom";
import { usePreloader } from "../../../contexts/preloader";
import { useDialog } from "../../../contexts/dialog";

const HomeProtected = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setAuthMessage } = useAuth();
  const { setStartPreloader } = usePreloader();
  const { setMessageDialog, setActionDialog } = useDialog();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [device, setDevice] = useState<any | null>(null);
  const [filtroBusca, setFiltroBusca] = useState("");
  const [titulos, setTítulos] = useState(["Nome", "Grupo", "Categoria", "Mac"]);

  const timerRef = useRef<any>(null);

  const loadPrevPage = () => {
    if (device?.current_page > 1) {
      DeviceAPI.listDevice({
        token: user?.token,
        page: device.current_page - 1,
      })
        .then((res: any) => {
          setDevice(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }
  };

  const loadNextPage = () => {
    if (device?.current_page < device?.last_page) {
      DeviceAPI.listDevice({
        token: user?.token,
        page: device.current_page + 1,
      })
        .then((res: any) => {
          setDevice(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }
  };

  const verDispositivo = (id: number) => {
    navigate(`/verdispositivo/${id}`);
  };

  const editaDispositivo = (id: number) => {
    navigate(`/editardispositivo/${id}`);
  };

  const excluirDispositivo = (id: number, name: string) => {
    setMessageDialog(`Confirma exclusão do dispositivo ${name}?`);
    setActionDialog(() => () => excluirDispositivoConfirma(id));
  };

  const excluirDispositivoConfirma = (id: number) => {
    setStartPreloader(true);
    DeviceAPI.deleteDevice({ token: user?.token, id: id })
      .then((res: any) => {
        setStartPreloader(false);
        navigate("/");
        setTimeout(() => navigate("/dispositivos"), 1000);
        setAuthMessage("Dispositivo excluído com sucesso");
      })
      .catch((err: Error | AxiosError) => {
        setStartPreloader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    let admin = false;
    user?.roles.map((roleUser: string, index: number) => {
      if (roleUser === "admin") {
        admin = true;
      }
    });
    setIsAdmin(admin);

    DeviceAPI.listDevice({
      token: user?.token,
      page: null,
    })
      .then((res: any) => {
        console.log(res);
        setDevice(res);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      });
    timerRef.current = setInterval(() => {
      DeviceAPI.listDevice({
        token: user?.token,
        page: null,
      })
        .then((res: any) => {
          console.log(res);
          setDevice(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }, 3000);

    return () => clearInterval(timerRef.current);
  }, [user]);

  function CheckIcon() {
    return (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="h-3 w-3 stroke-primary"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
        />
      </svg>
    );
  }

  return (
    <Container>
      <Main>
        <ContentRow>
          {device?.data.map(
            (
              {
                id,
                name,
                mac,
                group,
                canControl,
                status,
              }: {
                id: number;
                name: string;
                mac: string;
                group: any;
                canControl: boolean;
                status: any;
              },
              index: number
            ) => (
              <Card
                key={id}
                color="white"
                variant="gradient"
                className="max-w-[20rem] p-8 m-5 shrink-0"
              >
                <CardHeader
                  floated={false}
                  shadow={false}
                  color="transparent"
                  className="m-0 mb-0 rounded-none border-b border-white/10 pb-8 text-center"
                >
                  <Typography
                    variant="small"
                    color="gray"
                    className="font-normal uppercase"
                  >
                    Categoria:{" "}
                    <span className="text-secondary">
                      {group.category.name}
                    </span>
                    <br />
                    Grupo: <span className="text-secondary">{group.name}</span>
                  </Typography>
                  <Typography
                    variant="h3"
                    color="gray"
                    className="mt-6 flex justify-center items-center gap-1 text-xl font-normal"
                  >
                    <IconDispositivo />
                    <span className="text-secondary font-bold">{name}</span>
                  </Typography>
                </CardHeader>
                <CardBody className="p-0">
                  <ul className="flex flex-col gap-4">
                    {status &&
                      Object.entries(status).map(([key, value], index) => (
                        <li
                          key={key}
                          className="flex items-center gap-4 border border-secondary p-4 rounded-md"
                        >
                          <span className="text-secondary font-bold">
                            {status[key].nome}
                          </span>
                          <div className="font-normal">
                            {Object.entries(status[key]).map(
                              ([key2, value2], index2) =>
                                key2 !== "nome" &&
                                key2 !== "controle" &&
                                key2 !== "datetime" &&
                                status[key][key2] ? (
                                  <div key={key2}>
                                    {`${status[key][key2].nome}:`}
                                    <span className="text-secondary font-bold">{`${status[key][key2].valor} ${status[key][key2].unidade}`}</span>
                                  </div>
                                ) : (
                                  <div key={key2}></div>
                                )
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </CardBody>
                <CardFooter className="mt-12 p-0 flex justify-center items-center">
                  <Button
                    className="flex items-center gap-3 bg-secondary"
                    size="sm"
                    onClick={() => navigate(`/verdispositivo/${id}`)}
                  >
                    <IconDispositivo />
                    Acessar
                  </Button>
                </CardFooter>
              </Card>
            )
          )}
        </ContentRow>
      </Main>
    </Container>
  );
};

export const IconDispositivo = () => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
      fill="currentColor"
      className="h-5 w-5"
    >
      <path d="M27.059,31.25H7.941A4.2,4.2,0,0,1,3.75,27.059V7.941A4.2,4.2,0,0,1,7.941,3.75H27.059A4.2,4.2,0,0,1,31.25,7.941V27.059A4.2,4.2,0,0,1,27.059,31.25ZM7.941,6.25A1.693,1.693,0,0,0,6.25,7.941V27.059A1.693,1.693,0,0,0,7.941,28.75H27.059a1.693,1.693,0,0,0,1.691-1.691V7.941A1.693,1.693,0,0,0,27.059,6.25Z" />
      <path d="M22.119,24.75H12.881a2.635,2.635,0,0,1-2.631-2.631V12.881a2.634,2.634,0,0,1,2.631-2.631h9.238a2.634,2.634,0,0,1,2.631,2.631v9.238A2.635,2.635,0,0,1,22.119,24.75Zm-9.238-12a.131.131,0,0,0-.131.131v9.238a.131.131,0,0,0,.131.131h9.238a.131.131,0,0,0,.131-.131V12.881a.131.131,0,0,0-.131-.131Z" />
      <path d="M12.982,4.4A1.25,1.25,0,0,1,11.734,3.2l-.034-1A1.25,1.25,0,0,1,12.908.905,1.263,1.263,0,0,1,14.2,2.112l.034,1A1.252,1.252,0,0,1,13.025,4.4Z" />
      <path d="M17.516,4.4A1.251,1.251,0,0,1,16.267,3.2l-.033-1A1.249,1.249,0,0,1,17.442.905a1.223,1.223,0,0,1,1.291,1.208l.033,1A1.25,1.25,0,0,1,17.558,4.4Z" />
      <path d="M22.049,4.4A1.251,1.251,0,0,1,20.8,3.2l-.033-1A1.249,1.249,0,0,1,21.975.905a1.22,1.22,0,0,1,1.291,1.208l.033,1A1.25,1.25,0,0,1,22.091,4.4Z" />
      <path d="M32.081,23.334a1.25,1.25,0,0,1-.041-2.5l1-.034a1.25,1.25,0,1,1,.083,2.5l-1,.034Z" />
      <path d="M32.081,18.8a1.25,1.25,0,0,1-.042-2.5l1-.034a1.25,1.25,0,1,1,.085,2.5l-1,.034Z" />
      <path d="M32.081,14.266a1.25,1.25,0,0,1-.041-2.5l1-.033a1.25,1.25,0,1,1,.083,2.5l-1,.033Z" />
      <path d="M22.066,34.1a1.25,1.25,0,0,1-1.249-1.208l-.033-1A1.251,1.251,0,0,1,21.991,30.6,1.238,1.238,0,0,1,23.283,31.8l.033,1a1.249,1.249,0,0,1-1.207,1.291Z" />
      <path d="M17.532,34.1a1.249,1.249,0,0,1-1.248-1.209l-.033-1a1.25,1.25,0,0,1,1.208-1.29A1.237,1.237,0,0,1,18.75,31.8l.033,1a1.249,1.249,0,0,1-1.208,1.29Z" />
      <path d="M13,34.1a1.249,1.249,0,0,1-1.248-1.209l-.034-1a1.25,1.25,0,0,1,2.5-.082l.034,1a1.25,1.25,0,0,1-1.208,1.29Z" />
      <path d="M1.918,14.216a1.25,1.25,0,0,1-.041-2.5l1-.033a1.25,1.25,0,1,1,.083,2.5l-1,.033Z" />
      <path d="M1.918,18.75a1.25,1.25,0,0,1-.041-2.5l1-.034a1.25,1.25,0,1,1,.083,2.5l-1,.034Z" />
      <path d="M1.918,23.284a1.25,1.25,0,0,1-.042-2.5l1-.034a1.269,1.269,0,0,1,1.292,1.206,1.251,1.251,0,0,1-1.207,1.292l-1,.034Z" />
    </svg>
  );
};

export default HomeProtected;
