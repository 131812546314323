import React from "react";
import { Select, Option } from "@material-tailwind/react";

interface IControlledSelect {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const ControlledSelect = ({ value, setValue }: IControlledSelect) => {
  return (
    <div className="w-50 mx-2 my-2">
      <Select
        label="Taxa Amostragem"
        value={value}
        onChange={(val) => setValue(val || "")}
      >
        <Option value={"10"}>10 segundos</Option>
        <Option value={"30"}>30 segundos</Option>
        <Option value={"60"}>1 minuto</Option>
        <Option value={"300"}>5 minutos</Option>
        <Option value={"600"}>10 minutos</Option>
        <Option value={"1800"}>30 minutos</Option>
        <Option value={"3600"}>1 hora</Option>
      </Select>
    </div>
  );
};

export default ControlledSelect;
