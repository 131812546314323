import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface AlertContextType {
  typeAlert: any;
  setTypeAlert: Dispatch<SetStateAction<any>>;
  messageAlert: string;
  setMessageAlert: Dispatch<SetStateAction<string>>;
  openAlert: boolean;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

interface AlertProviderProps {
  children: ReactNode;
}

export function AlertProvider({ children }: AlertProviderProps) {
  const [typeAlert, setTypeAlert] = useState<any>("success");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [messageAlert, setMessageAlert] = useState<string>("Olá");
  return (
    <AlertContext.Provider
      value={{
        typeAlert,
        setTypeAlert,
        openAlert,
        setOpenAlert,
        messageAlert,
        setMessageAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}

export function useAlert(): AlertContextType {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert deve ser usado dentro de um AlertProvider");
  }
  return context;
}
