import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

interface DialogContextType {
  actionDialog: () => void;
  setActionDialog: Dispatch<SetStateAction<any>>;
  messageDialog: string;
  setMessageDialog: Dispatch<SetStateAction<string>>;
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

interface DialogProviderProps {
  children: ReactNode;
}

export function DialogProvider({ children }: DialogProviderProps) {
  const [actionDialog, setActionDialog] = useState<() => void>(() => {});
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [messageDialog, setMessageDialog] = useState<string>("");

  useEffect(() => {
    if (messageDialog && messageDialog !== "") {
      setOpenDialog(true);
    }
  }, [messageDialog]);

  useEffect(() => {
    if (!openDialog) {
      setMessageDialog("");
    }
  }, [openDialog]);

  return (
    <DialogContext.Provider
      value={{
        actionDialog,
        setActionDialog,
        openDialog,
        setOpenDialog,
        messageDialog,
        setMessageDialog,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
}

export function useDialog(): DialogContextType {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog deve ser usado dentro de um DialogProvider");
  }
  return context;
}
