import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const CategoryAPI = {
  createCategory: async function ({ token, dados }, cancel = false) {
    const response = await api.request({
      url: `/category`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: dados,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  updateCategory: async function ({ token, id, dados }, cancel = false) {
    const response = await api.request({
      url: `/category/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: dados,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  listCategory: async function ({ token, page }, cancel = false) {
    const response = await api.request({
      url: page ? `/category?page=${page}` : "/category",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    console.log(response.data);
    return response.data;
  },
  getCategory: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/category/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  deleteCategory: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/category/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(CategoryAPI);
