import { isDesktop } from "react-device-detect";
import tw from "tailwind-styled-components";

export const Container = tw.header`
    z-10
    flex
    flex-column
    flex-wrap
    justify-between
    items-center
    relative
    w-screen
    ${(p) => isDesktop && "w-full"}
    h-[100px]
    text-white
    pointer-events-none
    bg-transparent
`;

interface LogoPedraProps {
  $authenticated: Boolean | null;
}

export const LogoPedra = tw.img<LogoPedraProps>`
    absolute
    w-32
    object-cover
    left-10
    top-6
    ${(p) => p.$authenticated && "opacity-70"}
`;

interface LogoAlegriasProps {
  $authenticated: Boolean | null;
}

export const LogoAlegrias = tw.img<LogoAlegriasProps>`
    absolute
    w-24
    object-cover
    left-1/2
    top-7
    -translate-x-1/2
    ${(p) => p.$authenticated && "invert opacity-70 w-32"}
    pointer-events-auto
    cursor-pointer
`;
