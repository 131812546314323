import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const DeviceAPI = {
  createDevice: async function ({ token, dados }, cancel = false) {
    const response = await api.request({
      url: `/devices`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: dados,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  updateDevice: async function ({ token, id, dados }, cancel = false) {
    const response = await api.request({
      url: `/devices/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: dados,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  listDevice: async function ({ token, page }, cancel = false) {
    const response = await api.request({
      url: page ? `/devices?status=true&page=${page}` : "/devices?status=true",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    console.log(response.data);
    return response.data;
  },
  getDevice: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/devices/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getDeviceStatusSSE: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/devices/${id}/status`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  deleteDevice: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/devices/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  scanDevice: async function ({ token, id }, cancel = false) {
    const response = await api.request({
      url: `/devices/scan/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  sendCommand: async function (
    { token, id, setor, prop, value },
    cancel = false
  ) {
    const response = await api.request({
      url: `/devices/${id}/action?value=${value}&prop=${prop}&setor=${setor}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getHistory: async function (
    { token, id, startDateMiliseconds, stopDateMiliseconds, intervalSeconds },
    cancel = false
  ) {
    const response = await api.request({
      url: `/devices/${id}/history?start=${startDateMiliseconds}&stop=${stopDateMiliseconds}&each_s=${intervalSeconds}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(DeviceAPI);
