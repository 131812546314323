import tw from "tailwind-styled-components";

export const Container = tw.div`
    
    flex
    items-center
    justify-start
    flex-col
    w-full
    h-full
    py-20 
`;

export const Main = tw.main`
container
flex
flex-col
flex-nowrap
items-center
justify-center
`;
