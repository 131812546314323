import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import { Typography } from "@material-tailwind/react";

import logo from "../../assets/images/logo-pedrabranca-pb.png";
import logoDark from "../../assets/images/logo-pedrabranca-pb-dark.png";
import logoAlegrias from "../../assets/images/logo-alegriasdequintal.png";
import logoAlegriasHorizontal from "../../assets/images/logo-alegriasdequintal-horizontal.png";
import HeaderNav from "../HeaderNav";
import { useAuth } from "../../contexts/auth";
import { BrowserView } from "react-device-detect";

export const Footer = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <footer className="w-screen bg-gray-100 self-end p-8">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12  text-center md:justify-between px-10">
        <img src={logoDark} className="w-32" />
        <ul className="flex flex-wrap items-center justify-center gap-y-2 gap-x-0">
          <li className="flex flew-row justify-center items-center">
            <Typography
              as="a"
              href="/"
              color="blue-gray"
              className="font-normal transition-colors text-secondary hover:text-gray-800 focus:text-gray-800"
            >
              Home
            </Typography>
            <hr className="mx-8 border-gray-700 border-l w-0 opacity-25 h-10" />
          </li>
          <li className="flex flew-row justify-center items-center">
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors text-secondary hover:text-gray-800 focus:text-gray-800"
            >
              Reportar Problema
            </Typography>
            <hr className="mx-8 border-gray-700 border-l w-0 opacity-25 h-10" />
          </li>
          <li className="flex flew-row justify-center items-center">
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors text-secondary hover:text-gray-800 focus:text-gray-800"
            >
              Alegrias de Quintal
            </Typography>
            <hr className="mx-8 border-gray-700 border-l w-0 opacity-25 h-10" />
          </li>
          <li className="flex flew-row justify-center items-center">
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors text-secondary hover:text-gray-800 focus:text-gray-800"
            >
              Pedra Branca
            </Typography>
          </li>
        </ul>
      </div>
      <hr className="my-8 border-gray-700 opacity-25" />
      <Typography color="blue-gray" className="text-center font-normal">
        &copy; 2024 Alegrias de Quintal • Fazenda Pedra Branca
      </Typography>
    </footer>
  );
};
