import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineGraph(props: { options: any; data: any }) {
  const [newData, setNewData] = useState({
    datasets: [],
    labels: [],
    name: "",
  });

  useEffect(() => {
    if (props.data) {
      let dataTemp = props.data;
      dataTemp.datasets = dataTemp.datasets.filter((item: any) => item.label);
      setNewData(dataTemp);
    }
  }, [props.data]);

  return <Line options={props.options} data={newData} />;
}
