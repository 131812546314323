import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
  Textarea,
  CardBody,
  Switch,
  Slider,
  CardFooter,
} from "@material-tailwind/react";

import { Chart, Colors } from "chart.js";
import { Container, Main, Picker } from "./styled";
import { useEffect, useRef, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";
import { UsersAPI } from "../../../apis/UsersAPI";
import { RolesAPI } from "../../../apis/RolesAPI";
import { AxiosError, isAxiosError } from "axios";
import PhoneInput from "../../../components/ui/PhoneInput";
import { DeviceAPI } from "../../../apis/DeviceAPI";
import { CategoryAPI } from "../../../apis/CategoryAPI";
import { GroupsAPI } from "../../../apis/GroupsAPI";
import LineGraph from "../../../components/Charts/Line/Line";

import { serverBaseURL } from "../../../apis/configs/axiosConfigs";
import { IconDispositivo } from "../HomeProtected/HomeProtected";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import { command } from "../../../commands/commands";
import DatePic from "../../../components/DatePic/DatePic";
import { TimePic } from "../../../components/TimePic/TimePic";
import ControlledSelect from "../../../components/ControlledSelect/ControlledSelect";

const _ = require("lodash");

Chart.register(Colors);

const ViewDeviceHistoryProtected = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { authMessage, setAuthMessage, user } = useAuth();
  const { setStartPreloader } = usePreloader();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isEditingDevice = navigation.formData?.get("name") != null;
  let actionData = useActionData() as
    | { error: string; message: string; success: boolean }
    | undefined;
  const [message, setMessage] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);
  const [grupos, setGrupos] = useState<string[]>([]);
  const [grupoEscolhido, setGrupoEscolhido] = useState<number | undefined>(
    undefined
  );
  const [props, setProps] = useState<string>("");
  const updateRef = useRef<any>(null);
  const [lastDatetime, setLastaDatetime] = useState<any>();
  const [date, setDate] = useState<Date | undefined>(new Date(Date.now()));
  const [timeInit, setTimeInit] = useState<string>("00:00");
  const [timeEnd, setTimeEnd] = useState<string>("23:59");
  const [dataRate, setDataRate] = useState<string>("60");
  const [dados, setDados] = useState<any>([]);

  const toogleShowItem = (idProp: number) => {
    let tempItems = [...dados];
    if (tempItems[0].datasets[idProp].hidden) {
      tempItems[0].datasets[idProp].hidden = false;
    } else {
      tempItems[0].datasets[idProp].hidden = true;
    }
    setDados(tempItems);
  };

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      colors: {
        forceOverride: false,
      },
      legend: {
        position: "top" as const,
        onClick: (e: any, legendItem: any) => {
          toogleShowItem(legendItem.datasetIndex);
        },
      },
      title: {
        display: false,
        text: "",
      },
    },
  };

  const parseData = (rawData: any) => {
    DeviceAPI.getDevice({ token: user?.token, id: deviceId })
      .then((res: any) => {
        setData(res);
        setGrupoEscolhido(res.group_id);
        // console.log(res);
      })
      .catch((err: Error | AxiosError) => {
        setStartPreloader(false);
        console.log(err);
      });
    // console.log(event.data);
    const parsedData = rawData.data;

    // console.log("parsed data", parsedData);

    if (parsedData) {
      let finalData = {};
      let arrayFinal = new Array();
      let datetime = new Array();
      let dadosFinal: any = {};
      let itens: any = [];
      let propriedades: any = {};

      parsedData.map((item: any, index2: any) => {
        Object.keys(item).map((device: any, index2) => {
          if (!itens.includes(device)) {
            itens.push(device);
          }
          Object.keys(item[device]).map((propriedade, index2) => {
            if (
              propriedade !== "controle" &&
              propriedade !== "nome" &&
              propriedade !== "datetime"
            ) {
              if (!propriedades.hasOwnProperty(propriedade)) {
                propriedades[propriedade] = item[device][propriedade].nome;
              }
              if (!dadosFinal.hasOwnProperty(propriedade)) {
                dadosFinal[propriedade] = [];
              }

              dadosFinal[propriedade].push(
                Number(item[device][propriedade].valor)
              );
            } else if (propriedade === "datetime") {
              datetime.push(
                new Date(item[device][propriedade])
                  .toLocaleString()
                  .replace(",", "")
                  .split(" ")
              );
            }
          });
        });

        // setStartPreloader(false);
        // GroupsAPI.listGroups({
        //   token: user?.token,
        //   page: null,
        // })
        //   .then((res2: any) => {
        //     console.log("grupos", res2);
        //     setGrupos(res2.data);
        //     setStartPreloader(false);
        //   })
        //   .catch((err2: Error | AxiosError) => {
        //     if (isAxiosError(err2)) {
        //       console.log(err2.response?.data.message);
        //       setStartPreloader(false);
        //     }
        //   });
      });
      itens.map((item: any) => {
        finalData = {
          name: item,
          labels: datetime,
          datasets: Object.keys(propriedades).map((prop, index) => ({
            name: prop,
            label: propriedades[prop],
            hidden: false,
            data: dadosFinal[prop],
          })),
        };
        arrayFinal.push(finalData);
      });

      setDados(arrayFinal);
    }

    GroupsAPI.listGroups({
      token: user?.token,
      page: null,
    })
      .then((res2: any) => {
        // console.log("grupos", res2);
        setGrupos(res2.data);
        setStartPreloader(false);
      })
      .catch((err2: Error | AxiosError) => {
        if (isAxiosError(err2)) {
          console.log(err2.response?.data.message);
          setStartPreloader(false);
        }
      });
  };

  const getData = () => {
    if (user && deviceId) {
      const month = (date ? date?.getMonth() + 1 : 0)
        .toString()
        .padStart(2, "0");
      const day = date?.getDate().toString().padStart(2, "0");
      const startDate = new Date(
        date?.getFullYear() +
          "/" +
          month +
          "/" +
          day +
          " " +
          timeInit +
          ":00-03:00"
      );
      const endDate = new Date(
        date?.getFullYear() +
          "/" +
          month +
          "/" +
          day +
          " " +
          timeEnd +
          ":00-03:00"
      );
      DeviceAPI.getHistory({
        token: user?.token,
        id: deviceId,
        startDateMiliseconds: startDate.getTime().toString(),
        stopDateMiliseconds: endDate.getTime().toString(),
        intervalSeconds: dataRate,
      })
        .then((res: any) => {
          parseData(res);
        })
        .catch((err: Error | AxiosError) => {
          setStartPreloader(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setStartPreloader(true);
    getData();
  }, []);

  useEffect(() => {
    setStartPreloader(true);
    getData();
  }, [date, timeInit, timeEnd, dataRate]);

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
  }, []);

  useEffect(() => {
    if (isEditingDevice) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isEditingDevice]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    } else if (actionData && actionData.message && actionData.success) {
      if (actionData.success) {
        setAuthMessage(actionData.message);
        navigate("/dispositivos");
      }
    } else if (typeof actionData === "string") {
      setAuthMessage(actionData);
    }
  }, [actionData]);

  useEffect(() => {
    // console.log(dados);
  }, [dados]);

  return (
    <Container>
      <Main>
        {data && (
          <Card
            color="transparent"
            shadow={false}
            className="mt-8 mb-2 w-full max-w-screen-lg flex flex-col flex-nowrap items-center"
          >
            <Button
              className="w-40 mb-6 bg-primary text-secondary"
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
            <div className="mb-1 flex flex-row flex-wrap align-middle justify-center gap-6">
              <Typography variant="h6" className="flex flex-row">
                Nome:
                <Typography variant="paragraph" as="div" className="ml-1">
                  {data.name}
                </Typography>
              </Typography>
              <Typography variant="h6" className="flex flex-row">
                Mac:
                <Typography variant="paragraph" as="div" className="ml-1">
                  {data.mac}
                </Typography>
              </Typography>
              <Typography variant="h6" className="flex flex-row">
                Grupo:
                <Typography variant="paragraph" as="div" className="ml-1">
                  {grupos &&
                    grupos
                      ?.filter(
                        (gp: any, index: number) => gp.id === grupoEscolhido
                      )
                      .map((val: any) => val.name)}
                </Typography>
              </Typography>
            </div>
            <div className="w-full mt-5 mb-1 flex flex-row flex-wrap gap-6">
              {data &&
                data.status &&
                Object.keys(data.status).map((key, index) => {
                  return (
                    <Card
                      className="w-full"
                      key={index + Math.floor(Math.random() * (10000 - 0) + 0)}
                    >
                      <CardBody>
                        <Typography
                          variant="h5"
                          className="w-full flex flex-col"
                          key={
                            index + Math.floor(Math.random() * (10000 - 0) + 0)
                          }
                        >
                          {data.status[key].nome}
                        </Typography>

                        <ul>
                          {Object.keys(data.status[key]).map((key2, index2) => {
                            return key2 !== "nome" && key2 !== "datetime" ? (
                              key2 === "controle" ? (
                                <li key={index2}>
                                  <Typography
                                    variant="h6"
                                    as="div"
                                    className="mt-5"
                                  >
                                    Controles
                                  </Typography>
                                  <div className="flex flex-row">
                                    {data.status[key][key2].map(
                                      (key3: any, index3: number) => {
                                        return Object.keys(key3).map(
                                          (key4, index4) => {
                                            return key4 === "nome" ? (
                                              <Typography
                                                variant="h6"
                                                as="div"
                                                key={index4}
                                              >
                                                {`${key3[key4]} ${
                                                  key3["unidade"] !== ""
                                                    ? `(${key3["unidade"]})`
                                                    : ""
                                                }`}
                                                {":"}
                                              </Typography>
                                            ) : key3[key4] === "on_off" ? (
                                              <div
                                                className="ml-3 flex items-center"
                                                key={index4}
                                              >
                                                <Switch
                                                  className="hover:bg-secondary"
                                                  defaultChecked={false}
                                                  // checked={key3["valor"]}
                                                  onClick={() => {
                                                    // if (user && deviceId) {
                                                    //   command({
                                                    //     user: user,
                                                    //     deviceId: deviceId,
                                                    //     setor: "bomba",
                                                    //     prop: "on_off",
                                                    //     value: true,
                                                    //   });
                                                    // }
                                                    // const newCommand = {
                                                    //   ...data,
                                                    //   status: {
                                                    //     ...data.status,
                                                    //     [key]: {
                                                    //       ...data.status[key],
                                                    //       [key2]: [
                                                    //         {
                                                    //           ...data.status[
                                                    //             key
                                                    //           ][key2][index3],
                                                    //           valor: true,
                                                    //         },
                                                    //       ],
                                                    //     },
                                                    //   },
                                                    // };
                                                    // setData(newCommand);
                                                  }}
                                                />
                                              </div>
                                            ) : key3[key4] === "percent" ? (
                                              <div
                                                className="ml-3 flex items-center"
                                                key={index4}
                                              >
                                                <Slider
                                                  className="text-secondary"
                                                  size="md"
                                                  defaultValue={50}
                                                  // value={key3["valor"]}
                                                />
                                              </div>
                                            ) : (
                                              <></>
                                            );
                                          }
                                        );
                                      }
                                    )}
                                  </div>
                                </li>
                              ) : (
                                <li key={index2}>
                                  <div className="flex flex-row">
                                    {Object.keys(data.status[key][key2]).map(
                                      (key3, index3) => {
                                        return key3 === "nome" ? (
                                          <Typography
                                            variant="h6"
                                            as="div"
                                            key={index3}
                                          >
                                            {data.status[key][key2][key3]}
                                            {":"}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="paragraph"
                                            as="div"
                                            className="ml-1"
                                            key={index3}
                                          >
                                            {data.status[key][key2][key3]}{" "}
                                          </Typography>
                                        );
                                      }
                                    )}
                                  </div>
                                </li>
                              )
                            ) : key2 === "datetime" ? (
                              <div className="flex flex-row" key={index2}>
                                <Typography
                                  variant="h6"
                                  className="mb-4 flex flex-row"
                                >
                                  Atualizado em:
                                  <Typography
                                    variant="paragraph"
                                    as="div"
                                    className="ml-1"
                                  >
                                    {new Date(
                                      data.status[key][key2]
                                    ).toLocaleString()}
                                  </Typography>
                                </Typography>
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                        </ul>
                        <Picker>
                          <DatePic date={date} setDate={setDate} />
                          <TimePic
                            timeInit={timeInit}
                            setTimeInit={setTimeInit}
                            timeEnd={timeEnd}
                            setTimeEnd={setTimeEnd}
                          />

                          <ControlledSelect
                            value={dataRate}
                            setValue={setDataRate}
                          />
                        </Picker>
                        {dados[index] && (
                          <div className="mt-10 h-1/2">
                            <LineGraph options={options} data={dados[index]} />
                          </div>
                        )}
                      </CardBody>
                      <CardFooter className="mb-5 flex justify-center items-center">
                        <Button
                          className="flex items-center gap-3 bg-secondary"
                          size="sm"
                          onClick={() => {
                            navigate(`/verdispositivo/${deviceId}`);
                          }}
                        >
                          <ChartBarIcon color="white" className="h-4 w-4" />
                          Ver Tempo Real
                        </Button>
                      </CardFooter>
                    </Card>
                  );
                })}
            </div>
          </Card>
        )}
      </Main>
    </Container>
  );
};

export default ViewDeviceHistoryProtected;
