import {LoaderFunctionArgs,
redirect,
} from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import { useAuth } from "../contexts/auth";
import { GroupsAPI } from "../apis/GroupsAPI";
import { DeviceAPI } from "../apis/DeviceAPI";

export const editarDispositivoAction = (token: string | undefined) => async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let id = formData.get("id") as number | null;
    let nome = formData.get("nome") as string | null;
    let mac = formData.get("mac") as string | null;
    let grupo = formData.get("grupo") as number | null;
    let props = formData.get("props") as string | null;

    if(!token){
      return{
        error:"Você não tem permissão para a ação"
      } 
    }

    if(!id){
      return{
        error:"Você não tem permissão para a ação (id)"
      } 
    }
   
    if (!nome) {
      return {
        error: "Você precisa fornecer um nome para o dispositivo",
      };
    }

    if (!mac) {
      return {
        error: "Você precisa fornecer um mac para o dispositivo",
      };
    }

    if (!grupo) {
      return {
        error: "Você precisa fornecer um grupo para o dispositivo",
      };
    }

    if (!props) {
      props=""
    }

    let parsedMeta;
    if (props==="") {
      parsedMeta = {};
    }else{
      try{
        parsedMeta = JSON.parse(props)
      }catch(err){
        return {
          error: "Verifique os dados e a sintaxe do campo Propriedades",
        };
      }
    }

    try {
       let response = await editarDispositivo(token,id, nome, mac, grupo, parsedMeta);
       console.log(response);
       return response;
    } catch (error) {
      console.log(error);
      return {
        error: "Problema ao editar dispositivo. Tente novamente",
      };
    }
};

const editarDispositivo = async (token:string,id:number, nome:string, mac:string, grupo:number, props:string) => {
  let dados = {name:nome, mac:mac, group_id:Number(grupo),props:props};
  console.log(dados);
  const response = await DeviceAPI.updateDevice({
    token:token,
    id:id,
    dados:dados
  }).then((res: any)=>{
    return res;
  }).catch((err: Error | AxiosError)=>{
    if (isAxiosError(err)) {
      return err.response?.data.message;
    }
  })
  return response
}
