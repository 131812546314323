import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigation,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useEffect, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";

const Reset = () => {
  let location = useLocation();
  const { authMessage, setAuthMessage } = useAuth();
  let params = new URLSearchParams(location.search);
  let token = params.get("token") || "";
  let email = params.get("email") || "";
  let navigation = useNavigation();
  let isReseting = navigation.formData?.get("novaConfirma") != null;
  let actionData = useActionData() as { error: string } | undefined;
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
  }, []);

  useEffect(() => {
    if (isReseting) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isReseting]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    }
  }, [actionData]);

  return (
    <Container>
      <Main>
        <Card
          color="transparent"
          shadow={false}
          className=" place-items-center"
        >
          <Typography variant="h4" className="text-primary">
            Redefinição de senha
          </Typography>
          <Typography className="mt-1 font-normal text-primary">
            Preencha os campos abaixo para redefinir sua senha
          </Typography>
          <Form
            method="post"
            replace
            className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"
          >
            <input type="hidden" name="token" value={token} />
            <input type="hidden" name="email" value={email} />
            <div className="mb-1 flex flex-col gap-6">
              <Typography variant="h6" className="-mb-3 text-primary">
                Senha
              </Typography>
              <Input
                name="nova"
                type="password"
                size="lg"
                placeholder="********"
                className=" !border-primary focus:!border-secondary active:!border-secondary text-primary"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 text-primary">
                Confirmar Senha
              </Typography>
              <Input
                name="novaConfirma"
                type="password"
                size="lg"
                placeholder="********"
                className=" !border-primary focus:!border-secondary active:!border-secondary text-primary"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
            </div>
            <Button
              type="submit"
              disabled={isReseting}
              className="mt-6 bg-secondary text-primary"
              fullWidth
            >
              {isReseting ? "Redefinindo Senha" : "Redefinir Senha"}
            </Button>
            {actionData && actionData.error ? (
              <p className="text-danger text-center my-5">{actionData.error}</p>
            ) : null}
          </Form>
          <Link to="/" className="text-primary">
            voltar para Home
          </Link>
        </Card>
      </Main>
    </Container>
  );
};

export default Reset;
