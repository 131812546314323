import {LoaderFunctionArgs,
redirect,
} from "react-router-dom";

export const trocarAction = (trocar: (antiga: string, nova:string  , novaConfirma:string  )=>void) => async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let antiga = formData.get("senha_antiga") as string | null;
    let nova = formData.get("senha_nova") as string | null;
    let novaConfirma = formData.get("senha_nova_confirma") as string | null;
   
    if (!antiga) {
      return {
        error: "Você precisa fornecer a senha antiga para trocar sua senha",
      };
    }

    if (!nova) {
      return {
        error: "Você precisa fornecer a senha nova para trocar sua senha",
      };
    }

    if (!novaConfirma) {
      return {
        error: "Você precisa fornecer a senha nova para trocar sua senha",
      };
    }

    try {
      return await trocar(antiga, nova, novaConfirma);
    } catch (error) {
      console.log(error);
      return {
        error: "Problema ao trocar senha. Tente novamente",
      };
    }
};
  