import { isDesktop } from "react-device-detect";
import tw from "tailwind-styled-components";

export const Container = tw.div`
    flex
    flex-column
    flex-wrap
    w-full
    ${(p) => isDesktop && "justify-center"}
    h-auto
    min-h-[100vh]
    items-start
`;

interface LogoPedraProps {
  $authenticated: Boolean | null;
}

export const LogoPedra = tw.img<LogoPedraProps>`
    block
    w-32
    object-cover
    ${(p) => p.$authenticated && "opacity-70"}
`;
