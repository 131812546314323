import tw from "tailwind-styled-components";

export const Container = tw.div`
fixed
right-5
top-2
z-[999999]

&.inverted{
    invert
    opacity-70
}
`;
