import { Alert, Button } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { Container } from "./styled";
import { colors } from "@material-tailwind/react/types/generic";
import preloadLottie from "../../assets/lottie/preloading.json";
import { usePreloader } from "../../contexts/preloader";
import { Controls, Player } from "@lottiefiles/react-lottie-player";

interface PreloaderProps {}

const Preloader = ({}: PreloaderProps) => {
  const { startPreloader, setStartPreloader } = usePreloader();
  const playerRef = useRef<Player>(null);

  return startPreloader ? (
    <Container>
      <Player
        className="invert"
        src={preloadLottie}
        style={{ height: "150px", width: "150px" }}
        //   onEvent={(event) => {
        //     if (event === "load") {
        //       if (playerRef.current) {
        //         playerRef.current.play();
        //       }
        //     }
        //   }}
        loop
        autoplay
        ref={playerRef}
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
    </Container>
  ) : (
    <></>
  );
};

export default Preloader;

const Icon = () => {
  return (
    <Container>
      <svg
        xmlns="https://www.w3.org/2000/svg"
        fill="black"
        opacity=".4"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="h-6 w-6"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </Container>
  );
};
