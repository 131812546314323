import { Player, Controls } from "@lottiefiles/react-lottie-player";
import burgerMenuLottie from "../../../assets/lottie/burger-menu.json";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Container } from "./styled";
import { useAuth } from "../../../contexts/auth";

interface BurgerMenuProps {
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
}

const BurgerMenu = ({ openMenu, setOpenMenu }: BurgerMenuProps) => {
  const { user } = useAuth();
  const playerRef = useRef<Player>(null);

  const burgerToggle = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    if (playerRef.current) {
      if (openMenu) {
        playerRef.current.setPlayerSpeed(1.5);
      } else {
        playerRef.current.setPlayerSpeed(-2.5);
      }
      playerRef.current.play();
    }
  }, [openMenu]);

  return (
    <Container
      onClick={burgerToggle}
      className={user === null ? "" : user.token === null ? "" : "inverted"}
    >
      <Player
        src={burgerMenuLottie}
        style={{ height: "50px", width: "50px" }}
        //   onEvent={(event) => {
        //     if (event === "load") {
        //       if (playerRef.current) {
        //         playerRef.current.play();
        //       }
        //     }
        //   }}
        keepLastFrame
        ref={playerRef}
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
    </Container>
  );
};

export default BurgerMenu;
