import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useEffect, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";
import { GroupsAPI } from "../../../apis/GroupsAPI";
import { RolesAPI } from "../../../apis/RolesAPI";
import { AxiosError, isAxiosError } from "axios";
import PhoneInput from "../../../components/ui/PhoneInput";
import { CategoryAPI } from "../../../apis/CategoryAPI";

const AddGroupsProtected = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { authMessage, setAuthMessage, user } = useAuth();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isAddingGroups = navigation.formData?.get("senha_confirmar") != null;
  let actionData = useActionData() as
    | { error: string; message: string; success: boolean }
    | undefined;
  const [message, setMessage] = useState<string | null>(null);
  const [categorias, setCategorias] = useState<string[]>([]);
  const [categoriaEscolhida, setCategoriaEscolhida] = useState<
    number | undefined
  >(undefined);
  const [meta, setMeta] = useState<string>("");

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
    CategoryAPI.listCategory({
      token: user?.token,
      page: null,
    })
      .then((res2: any) => {
        console.log(res2);
        setCategorias(res2.data);
      })
      .catch((err2: Error | AxiosError) => {
        if (isAxiosError(err2)) {
          console.log(err2.response?.data.message);
        }
      });
  }, []);

  useEffect(() => {
    if (isAddingGroups) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isAddingGroups]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    } else if (actionData && actionData.message && actionData.success) {
      if (actionData.success) {
        setAuthMessage(actionData.message);
        navigate("/grupos");
      }
    } else if (typeof actionData === "string") {
      setAuthMessage(actionData);
    }
  }, [actionData]);

  return (
    <Container>
      <Main>
        <Card color="transparent" shadow={false}>
          <Form
            method="post"
            autoComplete="new-password"
            replace
            className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96 flex flex-col flex-nowrap items-center"
          >
            <div className="mb-1 flex flex-col w-full gap-6">
              <Typography variant="h6" className="-mb-3 ">
                Nome
              </Typography>
              <Input
                name="nome"
                type="text"
                autoComplete="new-password"
                size="lg"
                placeholder="Nome do grupo"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 ">
                Categoria
              </Typography>
              <Select
                key={Math.floor(Math.random() * 10000000)}
                label="Categoria"
                size="lg"
                onChange={(value) => {
                  setCategoriaEscolhida(Number(value));
                }}
                value={categoriaEscolhida?.toString()}
              >
                {categorias &&
                  categorias?.map((cat: any, index: number) => (
                    <Option key={index} value={cat.id.toString()}>
                      {cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
                    </Option>
                  ))}
              </Select>
              <input
                name="categoria"
                value={categoriaEscolhida}
                hidden
                readOnly
              />
              <Typography variant="h6" className="-mb-3 ">
                Metadados
              </Typography>
              <Textarea
                label="Metadados"
                onChange={(e) => {
                  setMeta(e.target.value);
                }}
              ></Textarea>
              <input name="meta" value={meta} hidden readOnly />
            </div>
            <Button
              type="submit"
              disabled={isAddingGroups}
              className="mt-6 bg-secondary "
              fullWidth
            >
              {isAddingGroups ? "Criando" : "Criar"}
            </Button>
            <Button
              className="mt-6 bg-transparent text-secondary"
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
          </Form>
        </Card>
      </Main>
    </Container>
  );
};

export default AddGroupsProtected;
