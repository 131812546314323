import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useEffect, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";
import { UsersAPI } from "../../../apis/UsersAPI";
import { RolesAPI } from "../../../apis/RolesAPI";
import { AxiosError, isAxiosError } from "axios";
import PhoneInput from "../../../components/ui/PhoneInput";

const EditUserProtected = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { authMessage, setAuthMessage, user } = useAuth();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isEditingUser = navigation.formData?.get("senha_confirmar") != null;
  let actionData = useActionData() as
    | { error: string; message: string; success: boolean }
    | undefined;
  const [message, setMessage] = useState<string | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [permissionsAdded, setPermissionsAdded] = useState<string[]>([]);
  const [nivel, setNivel] = useState<string | undefined>(undefined);

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
    RolesAPI.listRoles({ token: user?.token })
      .then((res: any) => {
        setRoles(res);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          console.log(err);
        } else {
          console.log(err);
        }
      });

    RolesAPI.listPermissions({ token: user?.token })
      .then((res: any) => {
        setPermissions(res);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          console.log(err);
        } else {
          console.log(err);
        }
      });
  }, []);

  useEffect(() => {
    if (isEditingUser) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isEditingUser]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    } else if (actionData && actionData.message && actionData.success) {
      if (actionData.success) {
        setAuthMessage(actionData.message);
        navigate("/usuarios");
      }
    } else if (typeof actionData === "string") {
      setAuthMessage(actionData);
    }
  }, [actionData]);

  useEffect(() => {
    console.log(permissionsAdded);
  }, [permissionsAdded]);

  useEffect(() => {
    UsersAPI.getUser({ token: user?.token, id: userId })
      .then((res: any) => {
        setData(res);
        setNivel(res.roles[0]);
        console.log(res);
      })
      .catch((err: Error | AxiosError) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (data) {
      setPermissionsAdded(data.permissions);
    }
  }, [data]);

  return (
    <Container>
      <Main>
        <Card color="transparent" shadow={false}>
          {data && (
            <Form
              method="post"
              autoComplete="new-password"
              replace
              className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96 flex flex-col flex-nowrap items-center"
            >
              <div className="mb-1 flex flex-col w-full gap-6">
                <Typography variant="h6" className="-mb-3 ">
                  Email
                </Typography>
                <Input
                  name="email"
                  type="email"
                  autoComplete="new-password"
                  size="lg"
                  placeholder="email@email.com"
                  className=" !border-none focus:!border-secondary active:!border-tertiary !text-gray-500 !bg-gray-100"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  defaultValue={data.email}
                  readOnly
                />
                <input name="id" value={userId} hidden readOnly />
                <Typography variant="h6" className="-mb-3 ">
                  Nível
                </Typography>
                <Select
                  key={Math.floor(Math.random() * 10000000)}
                  label="Nível"
                  size="lg"
                  onChange={(value) => {
                    setNivel(value);
                  }}
                  value={nivel}
                >
                  {roles &&
                    roles?.map((role) => (
                      <Option value={role}>
                        {role.charAt(0).toUpperCase() + role.slice(1)}
                      </Option>
                    ))}
                </Select>
                <input
                  name="nivel"
                  defaultValue={data.roles[0]}
                  value={nivel}
                  hidden
                  readOnly
                />

                <Typography variant="h6" className="-mb-3 ">
                  Permissões
                </Typography>
                {permissions &&
                  permissions?.map((permission) => (
                    <Checkbox
                      defaultChecked={data.permissions.includes(permission)}
                      label={permission}
                      value={permission}
                      className="my-[-30px] overflow-visible"
                      ripple={false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setPermissionsAdded((current) => [
                            ...current,
                            e.target.value,
                          ]);
                        } else {
                          setPermissionsAdded((current) => {
                            return current.filter((item) =>
                              item === e.target.value ? false : e.target.value
                            );
                          });
                        }
                      }}
                    />
                  ))}
                <input
                  name="permissionsAdded"
                  value={permissionsAdded}
                  hidden
                  readOnly
                />

                <Typography variant="h6" className="-mb-3 ">
                  Nome
                </Typography>
                <Input
                  name="nome"
                  type="text"
                  autoComplete="new-password"
                  size="lg"
                  placeholder="Nome da pessoa"
                  className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  defaultValue={data.name}
                />

                <Typography variant="h6" className="-mb-3 ">
                  Celular
                </Typography>
                <PhoneInput
                  name="celular"
                  autoComplete="new-password"
                  size="lg"
                  placeholder="(xx) xxxxx-xxxx"
                  className=" !border-secondary focus:!border-secondary active:!border-tertiary text-black"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  defaultValue={data.phone}
                />
              </div>
              <Button
                type="submit"
                disabled={isEditingUser}
                className="mt-6 bg-secondary "
                fullWidth
              >
                {isEditingUser ? "Atualizando" : "Atualizar"}
              </Button>
              <Button
                className="mt-6 bg-transparent text-secondary"
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </Form>
          )}
        </Card>
      </Main>
    </Container>
  );
};

export default EditUserProtected;
