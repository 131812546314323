import { Alert, Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Container } from "./styled";
import { colors } from "@material-tailwind/react/types/generic";
import { useAlert } from "../../contexts/alert";

interface AlertDismissibleProps {}

const AlertDismissible = ({}: AlertDismissibleProps) => {
  const { typeAlert, openAlert, setOpenAlert, messageAlert } = useAlert();
  return (
    <Alert
      style={{ width: "auto", maxWidth: "87vw" }}
      open={openAlert}
      color={typeAlert}
      action={
        <Button
          onClick={() => {
            setOpenAlert(false);
          }}
          variant="text"
          size="sm"
          className="!absolute top-0 bottom-0 right-0"
        >
          <Icon />
        </Button>
      }
      className="fixed z-50 max-w-sm top-6 right-6"
    >
      {messageAlert}
    </Alert>
  );
};

export default AlertDismissible;

const Icon = () => {
  return (
    <Container>
      <svg
        xmlns="https://www.w3.org/2000/svg"
        fill="black"
        opacity=".4"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="h-6 w-6"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </Container>
  );
};
