import tw from "tailwind-styled-components";

export const Container = tw.div`
 w-auto
 h-screen
 pointer-events-auto
`;

export const Nav = tw.nav`
    w-auto
`;

export const Ul = tw.ul`
    flex
    flex-row
    flex-nowrap
    list-none
    p-0
    m-0
    justify-between
`;

export const Li = tw.li`
mx-5`;
