import {LoaderFunctionArgs,
redirect,
} from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import { useAuth } from "../contexts/auth";
import { CategoryAPI } from "../apis/CategoryAPI";

export const editarCategoriaAction = (token: string | undefined) => async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let id = formData.get("id") as number | null;
    let nome = formData.get("nome") as string | null;

    if(!token){
      return{
        error:"Você não tem permissão para a ação"
      } 
    }

    if(!id){
      return{
        error:"Você não tem permissão para a ação (id)"
      } 
    }
   
    if (!nome) {
      return {
        error: "Você precisa fornecer um nome para cadastrar uma categoria",
      };
    }
    try {
       let response = await editarCategoria(token,id, nome);
       console.log(response);
       return response;
    } catch (error) {
      console.log(error);
      return {
        error: "Problema editar a categoria. Tente novamente",
      };
    }
};

const editarCategoria = async (token:string,id:number, nome:string) => {
  let dados = {name:nome};
  console.log(dados);
  const response = await CategoryAPI.updateCategory({
    token:token,
    id:id,
    dados:dados
  }).then((res: any)=>{
    return res;
  }).catch((err: Error | AxiosError)=>{
    if (isAxiosError(err)) {
      return err.response?.data.message;
    }
  })
  return response
}
