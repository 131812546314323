import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import AuthStatus from "../AuthStatus";

import { Container, LogoAlegrias, LogoPedra } from "./styled";

import logo from "../../assets/images/logo-pedrabranca-pb.png";
import logoDark from "../../assets/images/logo-pedrabranca-pb-dark.png";
import logoAlegrias from "../../assets/images/logo-alegriasdequintal.png";
import logoAlegriasHorizontal from "../../assets/images/logo-alegriasdequintal-horizontal.png";
import HeaderNav from "../HeaderNav";
import { useAuth } from "../../contexts/auth";
import { BrowserView } from "react-device-detect";

export const Header = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Container>
      {/* <AuthStatus /> */}
      <LogoAlegrias
        src={user?.token ? logoAlegriasHorizontal : logoAlegrias}
        alt="Logo Pedra Branca"
        $authenticated={
          user === null ? false : user.token === null ? false : true
        }
        onClick={() => navigate("/")}
      />
      <HeaderNav />
    </Container>
  );
};
