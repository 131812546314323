import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigation,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useEffect, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";

const ChangePasswordProtected = () => {
  let location = useLocation();
  const { authMessage, setAuthMessage } = useAuth();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isChangingPassword = navigation.formData?.get("senha_nova") != null;
  let actionData = useActionData() as { error: string } | undefined;
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
  }, []);

  useEffect(() => {
    if (isChangingPassword) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isChangingPassword]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    }
  }, [actionData]);

  return (
    <Container>
      <Main>
        <Card color="transparent" shadow={false}>
          <Form
            method="post"
            replace
            className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"
          >
            <div className="mb-1 flex flex-col gap-6">
              <Typography variant="h6" className="-mb-3 ">
                Senha Antiga
              </Typography>
              <Input
                name="senha_antiga"
                type="password"
                size="lg"
                placeholder="********"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 ">
                Senha Nova
              </Typography>
              <Input
                name="senha_nova"
                type="password"
                size="lg"
                placeholder="********"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 ">
                Confirmar Senha Nova
              </Typography>
              <Input
                name="senha_nova_confirma"
                type="password"
                size="lg"
                placeholder="********"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
            </div>
            <Button
              type="submit"
              disabled={isChangingPassword}
              className="mt-6 bg-secondary "
              fullWidth
            >
              {isChangingPassword ? "Mudando Senha" : "Mudar Senha"}
            </Button>
            {/* {actionData && actionData.error ? (
              <p style={{ color: "red" }}>{actionData.error}</p>
            ) : null} */}
          </Form>
        </Card>
      </Main>
    </Container>
  );
};

export default ChangePasswordProtected;
