import tw from "tailwind-styled-components";

export const Container = tw.div`
    pointer-events-auto
    absolute
    flex
    flex-column
    w-full
    h-auto
    min-h-full
    items-center
    justify-center
    bg-[rgba(255,255,255,.8)]
    z-50
`;