import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface PreloaderContextType {
  startPreloader: boolean;
  setStartPreloader: Dispatch<SetStateAction<boolean>>;
}

const PreloaderContext = createContext<PreloaderContextType | undefined>(
  undefined
);

interface PreloaderProviderProps {
  children: ReactNode;
}

export function PreloaderProvider({ children }: PreloaderProviderProps) {
  const [startPreloader, setStartPreloader] = useState<boolean>(false);
  return (
    <PreloaderContext.Provider
      value={{
        startPreloader,
        setStartPreloader,
      }}
    >
      {children}
    </PreloaderContext.Provider>
  );
}

export function usePreloader(): PreloaderContextType {
  const context = useContext(PreloaderContext);
  if (!context) {
    throw new Error(
      "usePreloader deve ser usado dentro de um PreloaderProvider"
    );
  }
  return context;
}
