import {LoaderFunctionArgs,
redirect,
} from "react-router-dom";
import { useAuth } from "../contexts/auth";


export const loginAction = (login: (keepAuthenticated: boolean, email: string,  password: string, redirectTo:string)=> any) => async ({ request }: LoaderFunctionArgs) => {
  
    let formData = await request.formData();
    let keepAuthenticated = formData.get("keepAuthenticated") as boolean | null;
    let email = formData.get("email") as string ;
    let password = formData.get("senha") as string;
    let redirectTo = formData.get("redirectTo") as string ;

  
    // Validate our form inputs and return validation errors via useActionData()
    if (!email) {
      return {
        error: "Você precisa fornecer um e-mail para fazer o login",
      };
    }

    if (!keepAuthenticated) {
      keepAuthenticated = false;
    }

    if (!password) {
      return {
        error: "Você precisa fornecer uma senha para fazer o login",
      };
    }
  
    // Sign in and redirect to the proper destination if successful.
    return await login(keepAuthenticated, email, password, redirectTo);
    
  };
  
  export const loginLoader = (isAuthenticated: null | boolean) => async () => {
    if (isAuthenticated) {
      return redirect("/");
    }
    return null;
  };